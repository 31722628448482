import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const Home = () => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  return (
    <div className="home-page">
      <h1>Memory Gallery</h1>
      <p>A secure place to store and view our images.</p>
      
      {/* Image shown ONLY to authenticated users */}
      {isAuthenticated && (
        <div className="home-image-container">
          <img 
            src="/assets/protected-home-page-image.jpg"
            alt="Memory Gallery" 
            className="home-feature-image"
          />
        </div>
      )}
      
      {/* Login CTA shown ONLY to non-authenticated users */}
      {!isAuthenticated && (
        <div className="cta-section">
          <p>Please log in to access your gallery.</p>
          <button 
            className="cta-button" 
            onClick={() => loginWithRedirect()}
          >
            Get Started
          </button>
        </div>
      )}
    </div>
  );
};

export default Home;