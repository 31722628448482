/**
 * Service for handling file uploads to the backend
 */

const API_BASE_URL = process.env.REACT_APP_API_URL.startsWith('http')
  ? process.env.REACT_APP_API_URL
  : `https://${process.env.REACT_APP_API_URL}`;

/**
 * Uploads files to the backend
 * 
 * @param {File[]} files - Array of File objects to upload
 * @param {string} userId - User identifier
 * @param {string} token - Authentication token
 * @param {Function} onProgressUpdate - Callback for progress updates
 * @returns {Promise<Object[]>} - Array of server responses
 */
export const uploadFiles = async (files, userId, token, onProgressUpdate) => {
  const results = [];
  
  // Define the function outside the loop
  const uploadFile = async (file, index) => {
    const formData = new FormData();
    formData.append('file', file); // Use 'file' as the parameter name

    // Log for debugging
    console.log(`Uploading file: ${file.name}`);
    console.log(`Using token: ${token.substring(0, 15)}...`);
    
    const result = await uploadSingleFile(
      formData, 
      token,
      (singleFileProgress) => {
        // Calculate overall progress
        if (onProgressUpdate) {
          const overallProgress = Math.round(
            ((index / files.length) + (singleFileProgress / 100) / files.length) * 100
          );
          onProgressUpdate(overallProgress);
        }
      }
    );
    onProgressUpdate((index + 1) / files.length * 100);
    return result;
  };
  
  for (let i = 0; i < files.length; i++) {
    // Pass the current file and index to the function
    results.push(await uploadFile(files[i], i));
  }
  
  return results;
};

/**
 * Uploads a single file to the backend
 * 
 * @param {FormData} formData - FormData with a single file
 * @param {string} token - Authentication token
 * @param {Function} onProgressUpdate - Callback for progress updates
 * @returns {Promise<Object>} - Server response
 */
const uploadSingleFile = (formData, token, onProgressUpdate) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    
    xhr.upload.addEventListener('progress', (event) => {
      if (event.lengthComputable && onProgressUpdate) {
        const percentComplete = Math.round((event.loaded / event.total) * 100);
        onProgressUpdate(percentComplete);
      }
    });
    
    xhr.open('POST', `${API_BASE_URL}/files`, true);
    xhr.setRequestHeader('Authorization', `Bearer ${token}`);
    
    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        try {
          const response = JSON.parse(xhr.responseText);
          resolve(response);
        } catch (error) {
          // If response is not valid JSON
          resolve({ success: true });
        }
      } else {
        // Log response text for debugging
        console.error(`Upload failed with status: ${xhr.status}`);
        try {
          const errorText = xhr.responseText;
          console.error('Error details:', errorText);
          reject(new Error(`Upload failed: ${errorText || xhr.statusText}`));
        } catch (e) {
          reject(new Error(`Upload failed with status: ${xhr.status}`));
        }
      }
    };
    
    xhr.onerror = () => {
      reject(new Error('Network error occurred during upload'));
    };
    
    xhr.send(formData);
  });
};