import React, { useState, useEffect, useCallback, memo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { fetchUserMedia, isVideo, isViewable } from '../services/media-service';
import { uploadFiles } from '../services/upload-service';

// Utility functions
const debug = (...args) => {
  if (process.env.NODE_ENV === 'development') {
    console.log('[Gallery]', ...args);
  }
};

// Fallback image constants
const PLACEHOLDER = {
  IMAGE: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='200' height='200' viewBox='0 0 200 200'%3E%3Crect width='200' height='200' fill='%23f0f0f0'/%3E%3Ctext x='50%25' y='50%25' font-size='24' text-anchor='middle' alignment-baseline='middle' fill='%23999'%3ENo Image%3C/text%3E%3C/svg%3E",
  VIDEO: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='200' height='200' viewBox='0 0 200 200'%3E%3Crect width='200' height='200' fill='%23f0f0f0'/%3E%3Ctext x='50%25' y='40%25' font-size='24' text-anchor='middle' alignment-baseline='middle' fill='%23999'%3EVideo%3C/text%3E%3Cpolygon points='70,80 130,100 70,120' fill='%23999'/%3E%3C/svg%3E",
  PDF: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='200' height='200' viewBox='0 0 200 200'%3E%3Crect width='200' height='200' fill='%23f0f0f0'/%3E%3Ctext x='50%25' y='50%25' font-size='24' text-anchor='middle' alignment-baseline='middle' fill='%23e74c3c'%3EPDF%3C/text%3E%3C/svg%3E",
  DOC: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='200' height='200' viewBox='0 0 200 200'%3E%3Crect width='200' height='200' fill='%23f0f0f0'/%3E%3Ctext x='50%25' y='50%25' font-size='24' text-anchor='middle' alignment-baseline='middle' fill='%233498db'%3EDoc%3C/text%3E%3C/svg%3E"
};

// Format utilities
const formatters = {
  fileSize: (bytes) => {
    if (bytes < 1024) return bytes + ' B';
    else if (bytes < 1048576) return (bytes / 1024).toFixed(1) + ' KB';
    else if (bytes < 1073741824) return (bytes / 1048576).toFixed(1) + ' MB';
    else return (bytes / 1073741824).toFixed(1) + ' GB';
  },
  date: (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  }
};

/**
 * Get placeholder for file type
 */
const getPlaceholder = (key) => {
  if (!key) return PLACEHOLDER.IMAGE;
  
  const extension = key.toLowerCase().split('.').pop();
  
  if (['jpg', 'jpeg', 'png', 'gif', 'webp'].includes(extension)) {
    return PLACEHOLDER.IMAGE;
  } else if (['mp4', 'webm', 'ogg', 'mov', 'avi'].includes(extension)) {
    return PLACEHOLDER.VIDEO;
  } else if (extension === 'pdf') {
    return PLACEHOLDER.PDF;
  } else {
    return PLACEHOLDER.DOC;
  }
};

// Simplified AuthenticatedMedia component - much simpler now!
const AuthenticatedMedia = memo(({ url, type = "image", alt, className, mimeType, title, isThumbnail = false }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [imageSrc, setImageSrc] = useState(
    type === "video" ? PLACEHOLDER.VIDEO : 
    type === "pdf" ? PLACEHOLDER.PDF :
    PLACEHOLDER.IMAGE
  );
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  // Load image when component mounts
  useEffect(() => {
    let isMounted = true;
    let objectUrl = null;
    
    // If we don't have a URL or it's already a data URL, use it directly
    if (!url || url.startsWith('data:') || url.startsWith('/assets/')) {
      setImageSrc(url || getPlaceholder(title));
      setLoading(false);
      return;
    }
    
    const loadImage = async () => {
      try {
        // Get the authentication token
        const token = await getAccessTokenSilently({
          authorizationParams: { audience: process.env.REACT_APP_AUTH0_AUDIENCE }
        });
        
        // Perform the fetch request - REMOVED timestamp for simplicity
        const response = await fetch(url, {
          headers: { 'Authorization': `Bearer ${token}` },
          cache: 'default' // Allow caching
        });
        
        if (!response.ok) {
          throw new Error(`Failed to load image: ${response.status}`);
        }
        
        // Get the image data as a blob
        const blob = await response.blob();
        objectUrl = URL.createObjectURL(blob);
        
        // If component is still mounted, update the state
        if (isMounted) {
          setImageSrc(objectUrl);
          setLoading(false);
          setError(null);
        }
      } catch (err) {
        console.error('Error loading media:', err);
        if (isMounted) {
          setLoading(false);
          setError(err.message);
        }
      }
    };
    
    loadImage();
    
    // Clean up function
    return () => {
      isMounted = false;
      if (objectUrl) {
        URL.revokeObjectURL(objectUrl);
      }
    };
  }, [url, getAccessTokenSilently, title, type]);
  
  // For thumbnails in the gallery grid
  if (isThumbnail) {
    return (
      <div className={`media-thumbnail ${type}`}>
        {loading && (
          <div className="thumbnail-loading">
            <div className="spinner"></div>
          </div>
        )}
        <img 
          src={imageSrc}
          alt={alt || title || 'Media content'} 
          className={`${loading ? 'loading' : ''} ${className || ''}`}
          style={{ opacity: loading ? 0.5 : 1 }}
          onError={() => {
            setImageSrc(getPlaceholder(title));
            setLoading(false);
          }}
          onLoad={() => setLoading(false)}
        />
        {type === 'video' && <div className="play-button">▶</div>}
      </div>
    );
  }
  
  // Loading state for full view
  if (loading) {
    return (
      <div className="media-loading">
        <div className="spinner"></div>
      </div>
    );
  }
  
  // Error state
  if (error) {
    return <div className="media-error"><p>Error: {error}</p></div>;
  }
  
  // For full view based on media type
  switch (type) {
    case 'video':
      return (
        <video className="media-viewer-video" controls autoPlay>
          <source src={imageSrc} type={mimeType} />
          Your browser does not support video.
        </video>
      );
    case 'pdf':
      return <iframe src={imageSrc} className="media-viewer-pdf" title={title} />;
    default: // image
      return (
        <img 
          src={imageSrc} 
          alt={alt || title || 'Media content'} 
          className={className || ''}
          onError={() => setImageSrc(PLACEHOLDER.IMAGE)}
        />
      );
  }
});

// Simplified gallery item component
const GalleryItem = memo(({ item, onClick }) => (
  <div className="gallery-item" onClick={() => onClick(item)}>
    <AuthenticatedMedia
      url={item.url}
      alt={item.title}
      className={!isViewable(item.key) ? "document-thumbnail" : ""}
      type={isVideo(item.key) ? 'video' : item.mimeType === 'application/pdf' ? 'pdf' : 'image'}
      title={item.title}
      mimeType={item.mimeType}
      isThumbnail={true}
    />
    <div className="gallery-item-info">
      <p className="item-title">{item.title}</p>
      <p className="item-details">
        {formatters.fileSize(item.size)} • {formatters.date(item.uploadDate)}
      </p>
    </div>
  </div>
));

// Updated MediaViewer component with fixed download functionality
const MediaViewer = memo(({ media, onClose, onNext, onPrevious }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [isGeneratingUrl, setIsGeneratingUrl] = useState(false);
  const [downloadError, setDownloadError] = useState(null);
  const [isZoomed, setIsZoomed] = useState(false);
  
  // Handle direct download with fresh token
  const handleDownload = async () => {
    setIsGeneratingUrl(true);
    setDownloadError(null);
    
    try {
      // Always get a fresh token when download is requested
      const token = await getAccessTokenSilently({
        authorizationParams: { 
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        },
      });
      
      // Create a temporary hidden anchor to trigger the download
      const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:3001';
      const downloadEndpoint = `${apiUrl}/files/download/${encodeURIComponent(media.key)}`;
      
      // Make authenticated fetch request to get the file
      const response = await fetch(downloadEndpoint, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      
      if (!response.ok) {
        throw new Error(`Download failed: ${response.status} ${response.statusText}`);
      }
      
      // Get the blob from the response and trigger download
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = media.title || 'download';
      document.body.appendChild(a);
      a.click();
      
      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
      
    } catch (error) {
      console.error("Download error:", error);
      setDownloadError("Failed to download file. Please try again.");
    } finally {
      setIsGeneratingUrl(false);
    }
  };
  
  // Add keyboard navigation support
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'ArrowRight' && onNext) {
        onNext();
      } else if (e.key === 'ArrowLeft' && onPrevious) {
        onPrevious();
      } else if (e.key === 'Escape') {
        onClose();
      }
    };
    
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [onNext, onPrevious, onClose]);
  
  // Determine media type
  const mediaType = isVideo(media.key) ? 'video' : 
                   media.mimeType === 'application/pdf' ? 'pdf' : 'image';
  
  return (
    <div className="media-viewer-overlay" onClick={onClose}>
      <div className="media-viewer-container" onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>×</button>
        
        {/* Navigation buttons */}
        {onPrevious && (
          <button 
            className="nav-button nav-prev" 
            onClick={onPrevious}
            aria-label="Previous image"
          >
            ‹
          </button>
        )}
        
        {onNext && (
          <button 
            className="nav-button nav-next" 
            onClick={onNext}
            aria-label="Next image"
          >
            ›
          </button>
        )}
        
        {/* Media content */}
        <AuthenticatedMedia 
          url={media.url} 
          type={mediaType}
          mimeType={media.mimeType}
          title={media.title}
          alt={media.title} 
          className={`media-viewer-main-content ${isZoomed ? 'zoomed' : ''}`}
        />
        
        <div className="media-viewer-details">
          <h3>{media.title}</h3>
          <p className="media-date">
            Uploaded: {formatters.date(media.uploadDate)} • {formatters.fileSize(media.size || 0)}
          </p>
          
          {/* Download button that uses real-time token */}
          <button 
            onClick={handleDownload}
            className="download-button"
            disabled={isGeneratingUrl}
          >
            {isGeneratingUrl ? 'Preparing...' : 'Download'}
          </button>
          
          {downloadError && (
            <div className="download-error">{downloadError}</div>
          )}

          <button 
            className="zoom-button"
            onClick={() => setIsZoomed(!isZoomed)}
            title={isZoomed ? "Fit to screen" : "View actual size"}
          >
            {isZoomed ? "🔍-" : "🔍+"}
          </button>
        </div>
      </div>
    </div>
  );
});

// Upload section component
const UploadSection = memo(({ 
  user, files, setFiles, uploading, uploadProgress, uploadSuccess, setUploadSuccess,
  uploadError, setUploadError, handleUpload
}) => {
  const handleFileChange = (e) => {
    setFiles(Array.from(e.target.files));
    setUploadError(null);
    setUploadSuccess(false);
  };

  return (
    <div className="upload-section">
      <h2>Upload New Files</h2>
      <div className="file-input-container">
        <input 
          type="file" 
          multiple 
          onChange={handleFileChange}
          disabled={uploading}
          id="file-upload"
          className="file-input"
        />
        <label htmlFor="file-upload" className="file-input-label">
          {uploading ? 'Uploading...' : 'Select Files'}
        </label>
        <span className="selected-files">
          {files.length > 0 ? `${files.length} files selected` : "No files selected"}
        </span>
        <button 
          onClick={handleUpload} 
          disabled={uploading || files.length === 0}
          className="upload-button"
        >
          {uploading ? `Uploading (${uploadProgress}%)` : 'Upload Files'}
        </button>
      </div>
      
      {uploading && (
        <div className="upload-progress">
          <div className="progress-bar">
            <div className="progress-bar-fill" style={{ width: `${uploadProgress}%` }}></div>
          </div>
          <span>{uploadProgress}%</span>
        </div>
      )}
      
      {uploadSuccess && <div className="upload-success">Files uploaded successfully!</div>}
      {uploadError && <div className="upload-error">Error: {uploadError}</div>}
      
      {files.length > 0 && (
        <div className="file-list">
          <h3>Files to upload:</h3>
          <ul>
            {files.map((file, index) => (
              <li key={index}>
                {file.name} - {formatters.fileSize(file.size)}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
});

// Simple pagination component - updated with First and Last buttons
const Pagination = memo(({ currentPage, totalPages, onPageChange }) => {
  if (totalPages <= 1) return null;
  
  return (
    <div className="pagination">
      <button 
        onClick={() => onPageChange(1)} 
        disabled={currentPage === 1}
        className="pagination-button pagination-first"
        title="Go to first page"
      >
        First
      </button>
      
      <button 
        onClick={() => onPageChange(currentPage - 1)} 
        disabled={currentPage === 1}
        className="pagination-button"
      >
        Previous
      </button>
      
      <div className="page-numbers">
        {Array.from({ length: Math.min(totalPages, 5) }, (_, i) => {
          let pageToShow;
          if (totalPages <= 5) {
            pageToShow = i + 1;
          } else if (currentPage <= 3) {
            pageToShow = i + 1;
          } else if (currentPage >= totalPages - 2) {
            pageToShow = totalPages - 4 + i;
          } else {
            pageToShow = currentPage - 2 + i;
          }
          
          return (
            <button
              key={pageToShow}
              className={`page-number ${currentPage === pageToShow ? 'current-page' : ''}`}
              onClick={() => onPageChange(pageToShow)}
            >
              {pageToShow}
            </button>
          );
        })}
      </div>
      
      <button 
        onClick={() => onPageChange(currentPage + 1)} 
        disabled={currentPage === totalPages}
        className="pagination-button"
      >
        Next
      </button>
      
      <button 
        onClick={() => onPageChange(totalPages)} 
        disabled={currentPage === totalPages}
        className="pagination-button pagination-last"
        title="Go to last page"
      >
        Last
      </button>
    </div>
  );
});

// Main Gallery component - updated for client-side filtering of all results
const Gallery = () => {
  const { user, getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [state, setState] = useState({
    // Upload state
    files: [],
    uploading: false,
    uploadProgress: 0,
    uploadSuccess: false,
    uploadError: null,
    
    // Media gallery state
    allMedia: [], // Store ALL media items across pages
    filteredMedia: [], // Results after applying filters
    displayedMedia: [], // Current page of filtered results to display
    loading: true,
    initialLoad: true, // Track if we've done the initial full load
    error: null,
    
    // Pagination state
    currentPage: 1, 
    itemsPerPage: 20,
    totalItems: 0,
    totalPages: 1, // Added missing totalPages property to initial state
    
    // Media viewer state
    selectedMedia: null,
    showViewer: false,
    
    // Filter and sort state
    filterType: 'all', // 'all', 'images', 'videos'
    sortOrder: 'newest' // 'newest', 'oldest', 'name'
  });
  
  // FIX: Use useState directly for setters
  const [settersFn] = useState(() => {
    const setterFunctions = {};
    Object.keys(state).forEach(key => {
      setterFunctions[key] = (value) => {
        setState(prev => ({
          ...prev,
          [key]: typeof value === 'function' ? value(prev[key]) : value
        }));
      };
    });
    return setterFunctions;
  });
  
  // Get auth token
  const getToken = useCallback(async () => {
    return await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      },
    });
  }, [getAccessTokenSilently]);
  
  // Enhanced applySortAndFilter with improved debugging and sorting
  const applySortAndFilter = useCallback((items, filterType, sortOrder) => {
    if (!items || items.length === 0) return [];
    
    debug(`Applying filter: ${filterType} and sort: ${sortOrder} to ${items.length} items`);
    
    // Step 1: Filter items by type
    let filtered = items;
    
    if (filterType === 'images') {
      filtered = items.filter(item => {
        const extension = item.key?.toLowerCase().split('.').pop();
        const isImageExt = ['jpg', 'jpeg', 'png', 'gif', 'webp', 'bmp', 'tiff'].includes(extension);
        const isImageMime = item.mimeType?.startsWith('image/');
        return isImageExt || isImageMime;
      });
    } else if (filterType === 'videos') {
      filtered = items.filter(item => {
        const extension = item.key?.toLowerCase().split('.').pop();
        const isVideoExt = ['mp4', 'webm', 'ogg', 'mov', 'avi', 'mpg', 'mpeg', 'mkv'].includes(extension);
        const isVideoMime = item.mimeType?.startsWith('video/');
        return isVideoExt || isVideoMime;
      });
    }
    
    // Step 2: Sort items - WITH ENHANCED LOGGING
    const sorted = [...filtered];
    
    if (sortOrder === 'newest') {
      debug('Sorting by NEWEST first');
      sorted.sort((a, b) => new Date(b.uploadDate) - new Date(a.uploadDate));
    } else if (sortOrder === 'oldest') {
      debug('Sorting by OLDEST first');
      sorted.sort((a, b) => new Date(a.uploadDate) - new Date(b.uploadDate));
    } else if (sortOrder === 'name') {
      debug('Sorting by NAME (A-Z)');
      sorted.sort((a, b) => (a.title || '').localeCompare(b.title || ''));
    }
    
    // Log the first few items after sorting to verify
    if (sorted.length > 0 && process.env.NODE_ENV === 'development') {
      if (sortOrder === 'newest' || sortOrder === 'oldest') {
        debug('First 3 items after sorting:', sorted.slice(0, 3).map(i => ({
          title: i.title,
          date: i.uploadDate,
          formattedDate: new Date(i.uploadDate).toLocaleString()
        })));
      } else {
        debug('First 3 items after sorting:', sorted.slice(0, 3).map(i => ({
          title: i.title
        })));
      }
    }
    
    return sorted;
  }, []);
  
  // Update displayed page based on current filter and pagination - FIXED for ESLint
const updateDisplayedMedia = useCallback(() => {
  const { filteredMedia, currentPage, itemsPerPage, displayedMedia, totalPages } = state;
  
  if (!filteredMedia || filteredMedia.length === 0) {
    settersFn.displayedMedia([]);
    return;
  }
  
  // Calculate pagination
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const pageItems = filteredMedia.slice(startIndex, endIndex);
  
  // Only log in development to reduce console noise
  if (process.env.NODE_ENV === 'development') {
    debug(`Displaying page ${currentPage}: items ${startIndex + 1}-${Math.min(endIndex, filteredMedia.length)} of ${filteredMedia.length}`);
  }
  
  // Only update if the items have actually changed - prevents unnecessary re-renders
  if (JSON.stringify(pageItems) !== JSON.stringify(displayedMedia)) {
    settersFn.displayedMedia(pageItems);
  }
  
  // Calculate total pages - Fixed to prevent unnecessary updates
  const newTotalPages = Math.max(1, Math.ceil(filteredMedia.length / itemsPerPage));
  if (totalPages !== newTotalPages) {
    setState(prev => ({
      ...prev,
      totalPages: newTotalPages
    }));
  }
}, [settersFn, setState, state]);
  
  // Improved loadAllMedia with deduplication
  const loadAllMedia = useCallback(async () => {
    if (!user || !isAuthenticated) return;
    
    settersFn.loading(true);
    settersFn.error(null);
    
    try {
      const token = await getToken();
      debug('Fetching all media items');
      
      // First, get total count by fetching first page
      const firstPageResponse = await fetchUserMedia(
        user.sub, 
        token, 
        1, 
        20
      );
      
      const totalItems = firstPageResponse.total;
      const totalPages = Math.ceil(totalItems / 20);
      
      debug(`Total items: ${totalItems}, Total pages: ${totalPages}`);
      const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:3001';
      
      // If we have more than one page, fetch all pages concurrently
      if (totalPages <= 1) {
        // Only one page, use the results we already have
        const formattedItems = firstPageResponse.items.map(item => ({
          ...item,
          url: item.url || `${apiUrl}/files/${encodeURIComponent(item.key)}`
        }));
        
        settersFn.allMedia(formattedItems);
        settersFn.totalItems(totalItems);
      } else {
        // Multiple pages, fetch them all
        const pagePromises = [];
        for (let page = 1; page <= totalPages; page++) {
          // Skip page 1, we already have it
          if (page === 1) {
            pagePromises.push(Promise.resolve(firstPageResponse));
          } else {
            pagePromises.push(fetchUserMedia(user.sub, token, page, 20));
          }
        }
        
        const allResponses = await Promise.all(pagePromises);
        
        // Combine all items from all pages with deduplication
        let allItems = [];
        const seenKeys = new Set(); // Track unique items by key or ID
        
        allResponses.forEach(response => {
          response.items.forEach(item => {
            const uniqueId = item.id || item.key;
            
            // Only add if we haven't seen this item before
            if (!seenKeys.has(uniqueId)) {
              seenKeys.add(uniqueId);
              allItems.push({
                ...item,
                url: item.url || `${apiUrl}/files/${encodeURIComponent(item.key)}`
              });
            } else {
              debug(`Skipping duplicate item: ${item.title} (${uniqueId})`);
            }
          });
        });
        
        debug(`Fetched ${allItems.length} unique items from ${totalPages} pages`);
        settersFn.allMedia(allItems);
        settersFn.totalItems(allItems.length);
      }
      
      settersFn.initialLoad(false);
    } catch (err) {
      console.error('Error fetching all media:', err);
      settersFn.error(err.message || 'Failed to load your files');
    } finally {
      settersFn.loading(false);
    }
  }, [user, isAuthenticated, getToken, settersFn]);
  
  // Apply filter and sort changes, then update displayed page
  useEffect(() => {
    if (state.allMedia.length > 0) {
      debug(`Applying filter/sort to all ${state.allMedia.length} items`);
      const filtered = applySortAndFilter(state.allMedia, state.filterType, state.sortOrder);
      settersFn.filteredMedia(filtered);
      
      // Adjust current page if it would be out of bounds after filtering
      const totalPages = Math.max(1, Math.ceil(filtered.length / state.itemsPerPage));
      if (state.currentPage > totalPages) {
        settersFn.currentPage(1);
      }
    }
  }, [
    state.allMedia, 
    state.filterType, 
    state.sortOrder, 
    state.itemsPerPage, 
    state.currentPage, // Added missing dependency
    applySortAndFilter, 
    settersFn
  ]);
  
  // Update displayed page whenever filtered results or current page changes - FIXED for ESLint
useEffect(() => {
  // This effect simply calls updateDisplayedMedia when dependencies change
  updateDisplayedMedia();
  
  // Explicitly adding state.currentPage to satisfy ESLint although updateDisplayedMedia
  // already depends on it. This is safe because we have checks to prevent unnecessary updates.
}, [updateDisplayedMedia, state.currentPage]);
  
  // Initial load of all media when component mounts
  useEffect(() => {
    if (user && state.initialLoad) {
      loadAllMedia();
    }
  }, [user, state.initialLoad, loadAllMedia]);
  
  // Reload media after upload success
  useEffect(() => {
    if (state.uploadSuccess) {
      loadAllMedia();
      settersFn.uploadSuccess(false);
    }
  }, [state.uploadSuccess, loadAllMedia, settersFn]);
  
  // Handle filter change
  const handleFilterChange = useCallback((filterType) => {
    debug(`Changing filter to: ${filterType}`);
    settersFn.filterType(filterType);
    settersFn.currentPage(1); // Reset to first page
  }, [settersFn]);
  
  // Force re-apply filter and sort when sort order changes
  const handleSortChange = useCallback((sortOrder) => {
    debug(`Changing sort order to: ${sortOrder}`);
    
    // Update state all at once to prevent stale data issues
    setState(prev => {
      const newFilteredMedia = applySortAndFilter(prev.allMedia, prev.filterType, sortOrder);
      return {
        ...prev,
        sortOrder,
        currentPage: 1, // Reset to first page
        filteredMedia: newFilteredMedia
      };
    });
  }, [applySortAndFilter]);
  
  // Handle page change
  const handlePageChange = useCallback((newPage) => {
    const totalPages = Math.max(1, Math.ceil(state.filteredMedia.length / state.itemsPerPage));
    if (newPage >= 1 && newPage <= totalPages && newPage !== state.currentPage) {
      debug(`Changing to page ${newPage}`);
      settersFn.currentPage(newPage);
      window.scrollTo(0, 0);
    }
  }, [state.filteredMedia.length, state.itemsPerPage, state.currentPage, settersFn]);
  
  // Retry loading all media
  const handleRetry = useCallback(() => {
    debug('Retrying media load');
    loadAllMedia();
  }, [loadAllMedia]);
  
  // Handle file upload - updated to use loadAllMedia
  const handleUpload = useCallback(async () => {
    if (state.files.length === 0) {
      settersFn.uploadError("Please select files to upload");
      return;
    }
    
    settersFn.uploading(true);
    settersFn.uploadProgress(0);
    settersFn.uploadError(null);
    
    try {
      const token = await getToken();
      
      const responses = await uploadFiles(
        state.files,
        user.sub,
        token,
        (progress) => settersFn.uploadProgress(Math.round(progress))
      );
      
      const success = responses?.length > 0 && responses.every(r => r?.success);
      
      if (success) {
        settersFn.uploadSuccess(true);
        settersFn.files([]);
        // Reset to first page and reload - using loadAllMedia now
        settersFn.currentPage(1);
        // This was using loadMedia before, now updated to loadAllMedia
        loadAllMedia();
      } else {
        settersFn.uploadError("Some files failed to upload");
      }
    } catch (error) {
      console.error('Error uploading files:', error);
      settersFn.uploadError(error.message || "Upload failed");
    } finally {
      settersFn.uploading(false);
    }
  }, [state.files, user, getToken, loadAllMedia, settersFn]);
  
  // Media viewer handlers
  const openMediaViewer = useCallback((mediaItem) => {
    debug("Opening media viewer for:", mediaItem.title);
    settersFn.selectedMedia(mediaItem);
    settersFn.showViewer(true);
    document.body.style.overflow = 'hidden';
  }, [settersFn]);
  
  const closeMediaViewer = useCallback(() => {
    settersFn.showViewer(false);
    document.body.style.overflow = '';
  }, [settersFn]);

  // Add these methods to your Gallery component
const navigateToNext = useCallback(() => {
  if (!state.selectedMedia || state.filteredMedia.length <= 1) return;
  
  const currentIndex = state.filteredMedia.findIndex(
    item => (item.id || item.key) === (state.selectedMedia.id || state.selectedMedia.key)
  );
  
  if (currentIndex >= 0 && currentIndex < state.filteredMedia.length - 1) {
    const nextItem = state.filteredMedia[currentIndex + 1];
    if (isViewable(nextItem.key)) {
      settersFn.selectedMedia(nextItem);
    }
  }
}, [state.selectedMedia, state.filteredMedia, settersFn]);

const navigateToPrevious = useCallback(() => {
  if (!state.selectedMedia || state.filteredMedia.length <= 1) return;
  
  const currentIndex = state.filteredMedia.findIndex(
    item => (item.id || item.key) === (state.selectedMedia.id || state.selectedMedia.key)
  );
  
  if (currentIndex > 0) {
    const prevItem = state.filteredMedia[currentIndex - 1];
    if (isViewable(prevItem.key)) {
      settersFn.selectedMedia(prevItem);
    }
  }
}, [state.selectedMedia, state.filteredMedia, settersFn]);
  
  // Render component - updated to use displayedMedia
  return (
    <div className="gallery-page">
      <h1>Memories of our lovely times together!</h1>
      <p>Welcome {user?.name}!</p>

      <UploadSection 
        user={user}
        files={state.files}
        setFiles={settersFn.files}
        uploading={state.uploading}
        uploadProgress={state.uploadProgress}
        uploadSuccess={state.uploadSuccess}
        setUploadSuccess={settersFn.uploadSuccess}
        uploadError={state.uploadError}
        setUploadError={settersFn.uploadError}
        handleUpload={handleUpload}
      />

      <h2>Our Memories</h2>
      
      {state.allMedia.length > 0 && !state.loading && (
        <div className="gallery-controls">
          <div className="filter-buttons">
            <button 
              className={`filter-button ${state.filterType === 'all' ? 'active' : ''}`}
              onClick={() => handleFilterChange('all')}
            >
              All Files ({state.allMedia.length})
            </button>
            <button 
              className={`filter-button ${state.filterType === 'images' ? 'active' : ''}`}
              onClick={() => handleFilterChange('images')}
            >
              Images ({applySortAndFilter(state.allMedia, 'images', state.sortOrder).length})
            </button>
            <button 
              className={`filter-button ${state.filterType === 'videos' ? 'active' : ''}`}
              onClick={() => handleFilterChange('videos')}
            >
              Videos ({applySortAndFilter(state.allMedia, 'videos', state.sortOrder).length})
            </button>
          </div>
          
          <div className="sort-controls">
            <label>Sort by: </label>
            <select 
              value={state.sortOrder} 
              onChange={(e) => handleSortChange(e.target.value)}
            >
              <option value="newest">Newest First</option>
              <option value="oldest">Oldest First</option>
              <option value="name">Name (A-Z)</option>
            </select>
          </div>
        </div>
      )}
      
      {state.loading && (
        <div className="loading-indicator">
          <div className="spinner"></div>
          <p>{state.initialLoad ? 'Loading all your files...' : 'Loading...'}</p>
        </div>
      )}
      
      {state.error && (
        <div className="error-message">
          <p>{state.error}</p>
          <button onClick={handleRetry} className="retry-button">Retry</button>
        </div>
      )}
      
      {!state.loading && !state.error && state.filteredMedia.length === 0 && (
        <div className="empty-gallery">
          {state.allMedia.length === 0 ? (
            <p>You haven't uploaded any files yet. Use the form above to get started!</p>
          ) : (
            <p>No files match your current filter. Try a different filter selection.</p>
          )}
        </div>
      )}
      
      {!state.loading && state.displayedMedia.length > 0 && (
        <>
          <div className="gallery-grid">
            {state.displayedMedia.map(item => (
              <GalleryItem 
                key={item.id || item.key}
                item={item}
                onClick={item => {
                  if (isViewable(item.key)) {
                    openMediaViewer(item);
                  } else {
                    window.open(item.url, '_blank');
                  }
                }}
              />
            ))}
          </div>
          
          <Pagination 
            currentPage={state.currentPage}
            totalPages={Math.ceil(state.filteredMedia.length / state.itemsPerPage)}
            onPageChange={handlePageChange}
          />
        </>
      )}
      
      {state.showViewer && state.selectedMedia && (
        <MediaViewer 
          media={state.selectedMedia} 
          onClose={closeMediaViewer}
          onNext={navigateToNext}
          onPrevious={navigateToPrevious}
        />
      )}
      
      {/* Development mode debugging panel - COMMENTED OUT FOR PRODUCTION
      {process.env.NODE_ENV === 'development' && (
        <div style={{ marginTop: '40px', padding: '10px', border: '1px solid #ccc', background: '#f8f9fa' }}>
          <h4>Debug Info:</h4>
          <p>All items: {state.allMedia.length}</p>
          <p>Filtered items: {state.filteredMedia.length}</p>
          <p>Displayed items: {state.displayedMedia.length}</p>
          <p>Current page: {state.currentPage}</p>
          <p>Items per page: {state.itemsPerPage}</p>
          <p>Total pages: {Math.ceil(state.filteredMedia.length / state.itemsPerPage)}</p>
          <p>Filter type: {state.filterType}</p>
          <p>Sort order: {state.sortOrder}</p>
          <button onClick={() => console.log('All Media:', state.allMedia)}>
            Log All Media
          </button>
          <button onClick={() => console.log('Filtered Media:', state.filteredMedia)}>
            Log Filtered Media
          </button>
        </div>
      )}
      */}
    </div>
  );
};

export default Gallery;
