import React from 'react';
import { NavLink } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import AuthButton from './AuthButton';

const Navbar = () => {
  const { isAuthenticated, isLoading } = useAuth0();

  return (
    <nav className="navbar">
      <div className="nav-brand">
        <NavLink to="/">Pia & Coj</NavLink>
      </div>
      <div className="nav-links">
        <NavLink to="/" className={({ isActive }) => isActive ? 'active' : ''}>
          Home
        </NavLink>
        {(isAuthenticated || isLoading) && (
          <>
            <NavLink to="/gallery" className={({ isActive }) => isActive ? 'active' : ''}>
              Gallery
            </NavLink>
            <NavLink to="/profile" className={({ isActive }) => isActive ? 'active' : ''}>
              Profile
            </NavLink>
          </>
        )}
      </div>
      <AuthButton />
    </nav>
  );
};

export default Navbar;