/**
 * Media Service - Handles all media-related operations and utilities
 */

// ===== CONSTANTS =====

// Format API base URL ensuring proper protocol
const API_BASE_URL = (() => {
  const url = process.env.REACT_APP_API_URL;
  if (!url) return 'http://localhost:3001'; // Fallback for development
  return url.startsWith('http') ? url : `https://${url}`;
})();

// File extension mappings for different file types
const FILE_TYPES = {
  IMAGE: ['jpg', 'jpeg', 'png', 'gif', 'webp', 'svg'],
  VIDEO: ['mp4', 'webm', 'mov', 'avi', 'mkv', 'flv', 'wmv', 'ogg'],
  DOCUMENT: ['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'txt', 'csv'],
  PDF: ['pdf'],
  AUDIO: ['mp3', 'wav', 'aac', 'ogg', 'm4a'],
  ARCHIVE: ['zip', 'rar', 'tar', '7z', 'gz']
};

// Viewable file types in browser
const VIEWABLE_TYPES = [
  ...FILE_TYPES.IMAGE,
  ...FILE_TYPES.VIDEO.filter(ext => ['mp4', 'webm', 'ogg'].includes(ext)),
  ...FILE_TYPES.PDF
];

// MIME type mappings
const MIME_TYPES = {
  // Images
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  png: 'image/png',
  gif: 'image/gif',
  webp: 'image/webp',
  svg: 'image/svg+xml',
  // Videos
  mp4: 'video/mp4',
  webm: 'video/webm',
  ogg: 'video/ogg',
  mov: 'video/quicktime',
  avi: 'video/x-msvideo',
  mkv: 'video/x-matroska',
  flv: 'video/x-flv',
  wmv: 'video/x-ms-wmv',
  // Documents
  pdf: 'application/pdf',
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  xls: 'application/vnd.ms-excel',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ppt: 'application/vnd.ms-powerpoint',
  pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  // Text
  txt: 'text/plain',
  csv: 'text/csv',
  html: 'text/html',
  css: 'text/css',
  js: 'text/javascript',
  // Audio
  mp3: 'audio/mpeg',
  wav: 'audio/wav',
  aac: 'audio/aac',
  // Archives
  zip: 'application/zip',
  rar: 'application/x-rar-compressed',
  tar: 'application/x-tar',
  '7z': 'application/x-7z-compressed'
};

// ===== DATA URI PLACEHOLDERS =====

export const DEFAULT_IMAGE_PLACEHOLDER = "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='200' height='200' viewBox='0 0 200 200'%3E%3Crect width='200' height='200' fill='%23f0f0f0'/%3E%3Ctext x='50%25' y='50%25' font-size='24' text-anchor='middle' alignment-baseline='middle' fill='%23999'%3ENo Image%3C/text%3E%3C/svg%3E";
export const DEFAULT_VIDEO_PLACEHOLDER = "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='200' height='200' viewBox='0 0 200 200'%3E%3Crect width='200' height='200' fill='%23f0f0f0'/%3E%3Ctext x='50%25' y='40%25' font-size='24' text-anchor='middle' alignment-baseline='middle' fill='%23999'%3EVideo%3C/text%3E%3Cpolygon points='70,80 130,100 70,120' fill='%23999'/%3E%3C/svg%3E";
export const DEFAULT_PDF_PLACEHOLDER = "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='200' height='200' viewBox='0 0 200 200'%3E%3Crect width='200' height='200' fill='%23f0f0f0'/%3E%3Ctext x='50%25' y='50%25' font-size='24' text-anchor='middle' alignment-baseline='middle' fill='%23e74c3c'%3EPDF%3C/text%3E%3C/svg%3E";
export const DEFAULT_DOC_PLACEHOLDER = "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='200' height='200' viewBox='0 0 200 200'%3E%3Crect width='200' height='200' fill='%23f0f0f0'/%3E%3Ctext x='50%25' y='50%25' font-size='24' text-anchor='middle' alignment-baseline='middle' fill='%233498db'%3EDoc%3C/text%3E%3C/svg%3E";

// ===== UTILITY FUNCTIONS =====

/**
 * Get file extension from a key or path
 * @param {string} key - The file key/path
 * @returns {string} - Lowercase file extension without the dot
 */
const getExtension = (key) => {
  if (!key || typeof key !== 'string') return '';
  return key.toLowerCase().split('.').pop();
};

/**
 * Extract a human-readable filename from the key
 * @param {string} key - The file key/path
 * @returns {string} - A clean filename
 */
export const getFileNameFromKey = (key) => {
  if (!key || typeof key !== 'string') return 'Unknown';
  
  // Remove timestamps if present (e.g., "1700654321-presentation.pptx" -> "presentation.pptx")
  const cleanKey = key.replace(/^\d+-/, '');
  
  // Extract filename from path if needed
  const parts = cleanKey.split('/');
  const filename = parts[parts.length - 1];
  
  try {
    return decodeURIComponent(filename);
  } catch (e) {
    // Handle invalid URI encoded strings
    return filename;
  }
};

/**
 * Get MIME type from file key/name
 * @param {string} key - File key/path
 * @returns {string} - MIME type
 */
export const getMimeTypeFromKey = (key) => {
  if (!key || typeof key !== 'string') return 'application/octet-stream';
  
  const extension = getExtension(key);
  return MIME_TYPES[extension] || 'application/octet-stream';
};

/**
 * Add cache-busting parameter to URL
 * @param {string} url - URL to modify
 * @returns {string} - URL with timestamp parameter
 */
const addCacheBuster = (url) => {
  if (!url) return url;
  const separator = url.includes('?') ? '&' : '?';
  return `${url}${separator}t=${Date.now()}`;
};

// ===== FILE TYPE CHECKING =====

/**
 * Check if file is of a specific type based on its extension
 * @param {string} key - The file key/path
 * @param {string[]} validExtensions - Array of valid extensions
 * @returns {boolean} - Whether the file matches the type
 */
const isFileType = (key, validExtensions) => {
  if (!key || typeof key !== 'string') return false;
  const extension = getExtension(key);
  return validExtensions.includes(extension);
};

/**
 * Determine if a file is an image
 * @param {string} key - The file key/path
 * @returns {boolean} - Whether the file is an image
 */
export const isImage = (key) => isFileType(key, FILE_TYPES.IMAGE);

/**
 * Determine if a file is a video
 * @param {string} key - The file key/path
 * @returns {boolean} - Whether the file is a video
 */
export const isVideo = (key) => isFileType(key, FILE_TYPES.VIDEO);

/**
 * Determine if a file is a document
 * @param {string} key - The file key/path
 * @returns {boolean} - Whether the file is a document
 */
export const isDocument = (key) => isFileType(key, FILE_TYPES.DOCUMENT);

/**
 * Determine if a file is a PDF
 * @param {string} key - The file key/path
 * @returns {boolean} - Whether the file is a PDF
 */
export const isPdf = (key) => isFileType(key, FILE_TYPES.PDF);

/**
 * Determine if a file is viewable in the browser
 * @param {string} key - The file key/path
 * @returns {boolean} - Whether the file is viewable
 */
export const isViewable = (key) => isFileType(key, VIEWABLE_TYPES);

// ===== URL GENERATION =====

/**
 * Generate a file URL with authentication and cache busting
 * @param {string} key - The file key/path
 * @returns {string} - Complete URL for the file
 */
export const generateFileUrl = (key) => {
  if (!key) return '';
  return addCacheBuster(`${API_BASE_URL}/files/${encodeURIComponent(key)}`);
};

/**
 * Generate a thumbnail URL based on the file type
 * @param {string} key - The file key/path
 * @returns {string} - URL for a thumbnail or data URI for placeholders
 */
export const generateThumbnailUrl = (key) => {
  if (!key) return DEFAULT_IMAGE_PLACEHOLDER;
  
  const extension = getExtension(key);
  
  // For images, use the actual file with thumbnail parameter
  if (FILE_TYPES.IMAGE.includes(extension)) {
    return addCacheBuster(`${API_BASE_URL}/files/${encodeURIComponent(key)}?thumbnail=true`);
  }
  
  // For videos, use dedicated thumbnail endpoint
  if (FILE_TYPES.VIDEO.includes(extension)) {
    return addCacheBuster(`${API_BASE_URL}/thumbnails/${encodeURIComponent(key)}`);
  }
  
  // For PDFs
  if (FILE_TYPES.PDF.includes(extension)) {
    return DEFAULT_PDF_PLACEHOLDER;
  }
  
  // For documents
  if (FILE_TYPES.DOCUMENT.includes(extension)) {
    return DEFAULT_DOC_PLACEHOLDER;
  }
  
  // Default fallback
  return DEFAULT_IMAGE_PLACEHOLDER;
};

// ===== API FUNCTIONS =====

/**
 * Create a media item object from API data
 * @param {Object} fileData - Raw file data from API
 * @returns {Object} - Standardized media item object
 */
const createMediaItem = (fileData) => {
  if (!fileData || !fileData.key) {
    console.warn('Invalid file data received from API:', fileData);
    return null;
  }
  
  return {
    id: fileData.etag || fileData.key,
    key: fileData.key,
    title: getFileNameFromKey(fileData.key),
    url: generateFileUrl(fileData.key),
    thumbnailUrl: generateThumbnailUrl(fileData.key),
    mimeType: getMimeTypeFromKey(fileData.key),
    size: fileData.size || 0,
    uploadDate: fileData.uploaded || new Date().toISOString(),
    description: fileData.description || '',
    metadata: fileData.metadata || {}
  };
};

/**
 * Fetches paginated media files for a user
 * @param {string} userId - The user ID
 * @param {string} token - Auth token
 * @param {number} page - Current page number (1-based)
 * @param {number} pageSize - Number of items per page
 * @returns {Promise<Object>} - Object containing items and total count
 */
export const fetchUserMedia = async (userId, token, page = 1, pageSize = 20) => {
  if (!userId || !token) {
    throw new Error('userId and token are required');
  }
  
  try {
    console.log(`Fetching media page ${page} with ${pageSize} items per page`);
    
    // Make API request
    const response = await fetch(`${API_BASE_URL}/files`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Accept': 'application/json'
      }
    });

    // Handle API errors
    if (!response.ok) {
      const errorText = await response.text();
      let errorMessage;
      try {
        const errorJson = JSON.parse(errorText);
        errorMessage = errorJson.message || `API error: ${response.status}`;
      } catch (e) {
        errorMessage = `API error: ${response.status}`;
      }
      throw new Error(errorMessage);
    }

    // Parse response
    const data = await response.json();
    
    // Validate response structure
    if (!data.files || !Array.isArray(data.files)) {
      console.error('Invalid response format:', data);
      throw new Error('Invalid response format from API');
    }

    // Transform items to standardized format
    const allItems = data.files
      .map(createMediaItem)
      .filter(Boolean); // Remove any null items
    
    // Get total count
    const totalCount = data.total || allItems.length;
    
    // Handle pagination client-side
    const startIndex = (page - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize, allItems.length);
    const paginatedItems = allItems.slice(startIndex, endIndex);
    
    console.log(`Pagination: showing items ${startIndex+1}-${endIndex} of ${totalCount}`);
    
    return {
      items: paginatedItems,
      total: totalCount
    };
  } catch (error) {
    console.error('Error fetching media:', error);
    throw error;
  }
};

/**
 * Fetches a single media item by key
 * @param {string} fileKey - The file key
 * @param {string} token - Auth token
 * @returns {Promise<Object>} - Media item details
 */
export const fetchMediaByKey = async (fileKey, token) => {
  if (!fileKey || !token) {
    throw new Error('fileKey and token are required');
  }
  
  try {
    const response = await fetch(`${API_BASE_URL}/files/${encodeURIComponent(fileKey)}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Accept': 'application/json'
      }
    });

    if (!response.ok) {
      const status = response.status;
      let errorMessage;
      
      try {
        const errorData = await response.json();
        errorMessage = errorData.message || `API error: ${status}`;
      } catch (e) {
        errorMessage = `API error: ${status}`;
      }
      
      throw new Error(errorMessage);
    }

    // Try to parse as JSON first
    try {
      const fileData = await response.json();
      return createMediaItem(fileData);
    } catch (e) {
      // If it's not JSON, return basic file info
      return {
        id: fileKey,
        key: fileKey,
        url: generateFileUrl(fileKey),
        title: getFileNameFromKey(fileKey),
        mimeType: getMimeTypeFromKey(fileKey),
        thumbnailUrl: generateThumbnailUrl(fileKey)
      };
    }
  } catch (error) {
    console.error(`Error fetching file ${fileKey}:`, error);
    throw error;
  }
};