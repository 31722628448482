import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const AuthButton = () => {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
  
  // Get the correct production URL
  const getLogoutUrl = () => {
    // In development, use window.location.origin
    if (window.location.hostname === 'localhost') {
      return window.location.origin;
    }
    
    // In production, use the actual deployed URL
    // Check if your Netlify URL is available in environment variables
    if (process.env.REACT_APP_SITE_URL) {
      return process.env.REACT_APP_SITE_URL;
    }
    
    // Fallback to removing the localhost part if present
    if (window.location.origin.includes('localhost')) {
      // This is a safeguard - remove localhost references
      return 'https://your-production-domain.com'; // Replace with your actual domain
    }
    
    return window.location.origin;
  };
  
  const handleLogout = () => {
    const returnTo = getLogoutUrl();
    console.log('Logging out, redirecting to:', returnTo);
    logout({ returnTo });
  };
  
  return (
    <div>
      {!isAuthenticated ? (
        <button 
          onClick={() => loginWithRedirect()} 
          className="btn-login"
        >
          Log In
        </button>
      ) : (
        <button 
          onClick={handleLogout}
          className="btn-logout"
        >
          Log Out
        </button>
      )}
    </div>
  );
};

export default AuthButton;