import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const Profile = () => {
  const { user, isLoading } = useAuth0();

  if (isLoading) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <div className="profile-page">
      <h1>Your Profile</h1>
      <div className="profile-card">
        <div className="profile-image">
          <img src={user?.picture} alt={user?.name} />
        </div>
        <div className="profile-details">
          <h2>{user?.name}</h2>
          <p>{user?.email}</p>
          <p>Member since: {new Date().toLocaleDateString()}</p>
        </div>
      </div>
    </div>
  );
};

export default Profile;